<template>
  <div class="container sidebar">
    <el-menu 
      :default-active="menuActive" 
      :default-openeds="[menuOpen]" 
      class="el-menu-vertical-demo" 
      @open="$store.commit('setMenuOpen', $event)">

      <div v-for="(item, index) in routers" :key="index">
        
        <el-submenu :index="index + ''" v-if="item.children.length > 1">
          <template slot="title">
            <i :class="item.icon"></i>
            <span>{{ item.title }}</span>
          </template>
          <el-menu-item v-for="(only, idx) in item.children" :key="idx" :index="index + '-' + idx" @click="handelActive(index + '-' + idx, only)">
            {{ only.title }}
          </el-menu-item>
        </el-submenu>

        <el-menu-item
          :index="index + ''" 
          v-else 
          @click="handelActive(index + '', item)">
          <i :class="item.icon"></i>
          <span>{{ item.title }}</span>
        </el-menu-item>
        
      </div>
      
    </el-menu>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: "menul",
    data() {
      return {
      }
    },

    computed: {
      ...mapState({
        // 路由菜单
        routers(state) {
          let newRouter = state.routers
          newRouter.forEach(item => {
            // 设置title
            if (!item.title) {
              item.title = item.children[0].title
            }

            // 设置路径
            if (item.children.length == 1) {
              item.path = item.children[0].path
            }
          })

          console.log(newRouter)
          return newRouter
        },

        // 打开的菜单
        menuOpen: state => state.menuOpen,

        // 选中的菜单
        menuActive: state => state.menuActive,
      })
    },

    created() {
      console.log(this.routers)
    },

    activated() {
      console.log("激活")
    },

    methods: {
      // 打开
      handleOpen(e) {
        this.currentOpen = e
      },

      // 跳转
      handelActive(index, item) {
        if (index.length == 1) {
          this.$store.commit('setMenuOpen', 0)
        }
        
        this.$router.replace(item.path)
        this.$store.commit("setMenuActive", index)
      },

    },
  }
</script>
<style scoped lang="scss">
  .sidebar {
    .el-menu {
      border-right-width: 0px;
    }
  }
</style>