<template>
  <div class="container">
    <el-container class="main-page">
      <el-header class="header row-center">
        <div class="row-center" @click="handleGoIndex">
          <img class="logo-img" src="../assets/logo.jpeg" alt="漫享CLUB" />
          <el-link class="logo-text">漫享CLUB</el-link>
        </div>

        <div class="row-center">
          <el-link @click="handleGoLogout()">退出</el-link>
        </div>
      </el-header>

      <el-container class="middle-box">
        <el-aside class="aside" width="200px">
          <menul />
        </el-aside>

        <el-container class="main">
          <el-main>
            <transition>
              <keep-alive :include="includePages">
                <router-view />
              </keep-alive>
            </transition>
          </el-main>
        </el-container>
      </el-container>

    </el-container>
  </div>
</template>

<script>
  import menul from "@/components/Menuz"
  export default {
    name: "master",
    components: { menul },
    data() {
      return {
        /**
         * matchList 赛事列表
         * powerList 权限列表
         * menuList 菜单列表
         * userList 用户列表
         * groupList 赛事 分组
         * allJoinMatchPeople 参赛人列表
         * setIndex banner 位置列表
         * 
        */
        includePages: "matchList,powerList,menuList,userList,groupList,allJoinMatchPeople,setIndex,rolesList,LineAdd"
      }
    },
    created() { 
      // skip
    },
    methods: {
      // 退出
      handleGoLogout() {
        this.$router.replace("/login")
      },

      // 去首页
      handleGoIndex() {
        this.$store.commit('setMenuActive', 0)
        this.$router.replace('/')
      }
    }
  }
</script>

<style lang="scss">
.container {
  height: 100%;

  .main-page {
    height: 100%;
    display: flex;
    flex-direction: column;

    .middle-box {
      flex: 1;
      overflow-y: auto;
    }

    .el-link {
      margin-left: 20px;
      color: #fff;

      &.el-link--info {
        color: #909399;
      }
    }

    .row-center {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .logo-img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      cursor: pointer;
      border: 2px solid #fff;
    }

    .header {
      background: linear-gradient(to right, rgba(#fef150, .8), rgba(#4198de, .8));
    }

    .header .logo-text {
      color: rgba(65, 152, 222, 1.0);
      font-weight: 500;
    }

    .main {
      background-color: #f3f5f5;
    }
  }

  .main-wrapper {
    .page-header {
      margin: 0 0 15px 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      .name {
        font-weight: 500;
        color: #1f2f3d;
        font-size: 22px;
      }

      .actions {
        flex: 1;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: flex-end;

        .item-line {
          margin: 0 15px 0 0;

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    .pick-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 0;
      
      &.justify-flex-end {
        justify-content: flex-end;
      }

      .item-line {
        margin: 0 15px 0 0;
        display: flex;
        align-items: center;

        &:last-child {
          margin: 0;
        }

        &.search {
          flex: 0 0 280px;
        }

        .el-dropdown {
          margin-right: 20px;
        }

        .btn-item {
          margin-left: 10px;
        }
      }
      
    }

    .table-list {
      width: 100%;

      .cover {
        width: 120px;
        height: auto;
      }

      .button-group {
        margin-bottom: 10px;
      }
    }

    .paginate-box {
      margin: 10px 0;
      text-align: center;
      display: flex;
      justify-content: space-between;
    }

    .page-content {
      .field-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px;

        .label {
          flex: 0 0 240px;
          text-align: right;
        }

        .field-source {
          flex: 1;
          margin-left: 20px;
        }
        .field-actions {
          text-align: right;
          padding: 40px 0;
        }
      }

      .editor-content {
        .ql-container  {
          min-height: 200px;
        }
      }
    }
    .upload-item {
      .el-image__inner {
        border-radius: 5px;
      }
    }

    .field-slides {
      display: flex;
      flex-wrap: wrap;
    }
    .upload-container {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #DCDFE6;
      border-radius: 5px;
      width: 100px;
      height: auto;
      cursor: pointer;
      overflow: hidden;

      .upload-button {
        font-size: 22px;
        color: #909399;
      }
    }
    .upload-item {
      position: relative;
      height: 100px;
      border: 1px solid #DCDFE6;
      border-radius: 5px;
      margin-right: 10px;
      overflow: hidden;

      .el-icon-delete-solid {
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 1;
        color: red;
      }
    }

    .map-box {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: .5);

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .map-info {
        width: 80%;
        height: 80%;
      }

      .map-box__btm {
        margin: 30px 0;

        button {
          width: 80px;
          height: 40px;
        }
      }
    }
  }

}

.item-line {
  .el-dropdown {
    margin-left: 10px;
  }

  .btn-item {
    margin-left: 10px;
  }
  
}
</style>