import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueBus from 'vue-bus'

 
Vue.use(VueBus);

// element组建挂载
// import "./el-components"

import './http'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import BaiduMap from "vue-baidu-map"
Vue.use(BaiduMap, {
  ak: "snZZxGi2MUQEDPjbHAfw5t4IlxUZCkQF"
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
