import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    host: "https://api.citypace.net/v1.0", // api域名
    routers: [],
    menuOpen: 0, // 设置菜单打开
    menuActive: "0", // 设置菜单选中
    token: null, // token
    matchInfo: {}, // 赛事详情
    setWxappActiveIndex: 0, // 小程序设置页面 选中的索引
    setCloudActiveIndex: 0, // 云运动
  },
  getters: {
  },
  mutations: {
    // 设置路由
    setRouters(state, routers) {
      state.routers = routers
    },

    // 设置菜单打开
    setMenuOpen(state, option) {
      state.menuOpen = option
    },

    // 设置菜单选中
    setMenuActive(state, option) {
      state.menuActive = option
    },

    // 设置token
    setToken(state, token) {
      state.token = token
    },

    // 清空store
    clearStore(state) {
      state.routers = []
      state.menuOpen = 0 // 设置菜单打开
      state.menuActive = "0" // 设置菜单选中
      state.token = null // token
    },

    // 设置赛事详情
    setMatchInfoAtState(state, info) {
      state.matchInfo = info
    },

    // 小程序设置页面 选中的索引
    _setWxappActiveIndex(state, idx) {
      state.setWxappActiveIndex = idx
    },

    // 云运动设置页面 选中的索引
    _setCloudActiveIndex(state, idx) {
      state.setCloudActiveIndex = idx
    },
  },
  actions: {
    // 设置路由
    setRoutersAsync(context, routers) {
      context.commit("setRouters", routers)
    },

    // 设置赛事详情
    setMatchInfo(context, info) {
      context.commit("setMatchInfoAtState", info)
    }
  },
  modules: {
  },

  plugins: [
    // 路由
    createPersistedState({
      key: "routers"
    }),
    // 菜单打开
    createPersistedState({
      key: "menuOpen"
    }),
    // 菜单选中
    createPersistedState({
      key: "menuActive"
    }),
    // token
    createPersistedState({
      key: "token"
    }),
    // 赛事详情
    createPersistedState({
      key: "matchInfo"
    }),
    // 小程序设置页面 选中的索引
    createPersistedState({
      key: "setWxappActiveIndex"
    }),
  ]
})
