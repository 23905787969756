import axios from 'axios'
import store from '@/store'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_API_HOST, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (config.url == "auth/login") {
      config.headers['Authorization'] = 'Basic ' + btoa(config.data.username + ':' + config.data.password)
    } else {
      config.headers['Authorization'] = 'Basic ' + btoa(store.state.token + ':' + store.state.token)
    }
    
    config.headers["Content-Type"] = "application/json; charset=UTF-8;"
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // // if the custom code is not 200, it is judged as an error.
    // if (res.status.code !== 200) {
    //   return Promise.reject(new Error(res.message || 'Error'))
    // } else {
    //   return res
    // }

    return res
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
